import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated } from '@azure/msal-react';

import { UserSvg } from '@assets/svgs';

import { OverlayMenu, Text, MenuItem, ItemsDivider, DropdownBase } from './DropdownBase';

export const DropdownProfile: React.FC = () => {
  const authenticated = useIsAuthenticated();
  const { t } = useTranslation();
  return authenticated ? (
    <DropdownBase
      overlay={
        <OverlayMenu>
          <MenuItem key={0}>
            <Text>
              <Link to="/workspace/workflow">Admin page</Link>
            </Text>
          </MenuItem>
          <ItemsDivider />
          <MenuItem key={1}>
            <Text>
              <Link to="/logout">{t('header.logout')}</Link>
            </Text>
          </MenuItem>
        </OverlayMenu>
      }
    >
      <UserSvg />
    </DropdownBase>
  ) : null;
};
