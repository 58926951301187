import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { State } from './type';
import { emailTest } from './action';

const initialState: State = { sidebarCollapsed: false, sidebarDrawerVisible: false };

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCollapsed: (state, payload: PayloadAction<boolean>) => {
      state.sidebarCollapsed = payload.payload;
    },
    setSidebarDrawerVisible: (state, payload: PayloadAction<boolean>) => {
      state.sidebarDrawerVisible = payload.payload;
    },
  },
});

// > Export
// * Action
export const actions = { ...slice.actions, emailTest };
// * Reducer
export const { reducer } = slice;
