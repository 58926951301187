import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@api';
import { createAsyncAction } from '@helpers/thunkWrapper';
import { buildUrl } from '@helpers/utils';
import { ActionParams, ModelModel, Pagination, Queries, Response } from '@types';

export const fetch = createAsyncThunk(
  'models/get',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/models/${payload.params.id}`;
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);

export const fetchMany = createAsyncThunk(
  'models/getMany',
  createAsyncAction(async (payload: ActionParams<Queries<{ project_id: string }>, any>) => {
    const endpoint = buildUrl(`/models`, payload?.params);
    const response = await api.caller.get<Response<Pagination<ModelModel>>>(endpoint);
    const data = response.data;

    return data;
  })
);

export const create = createAsyncThunk(
  'models/create',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/models`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const update = createAsyncThunk(
  'models/update',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/models/${payload.params.id}`;
    const response = await api.caller.put(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);

export const remove = createAsyncThunk(
  'models/remove',
  createAsyncAction(async (payload: ActionParams<{ id: string }>) => {
    const endpoint = `/models/${payload.params?.id}`;
    const response = await api.caller.delete(endpoint);
    const data = response.data;

    return data;
  })
);

export const removeMany = createAsyncThunk(
  'models/removeMany',
  createAsyncAction(async (payload: ActionParams<{ ids: string[] }>) => {
    const endpoint = `/models?action=delete`;
    const response = await api.caller.patch(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
