import { Col, Spin } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Card } from './styles';

const Wrapper = styled(Card)`
  & > *:not(:last-child) {
    margin-bottom: 6px;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const List = styled.div`
  display: flex;
`;

const Item = styled.div`
  flex: 1;
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Title = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.25px;
`;

const Text = styled.p`
  font-size: 1.43rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.15px;
  color: black;
`;

type Props = {
  items: { title: string; text: string }[];
  loading?: boolean;
  link?: { title: string; to: string };
};

export const CardStat: React.FC<Props> = ({ items, loading, link }) => (
  <Col xs={24} md={12}>
    <Wrapper>
      {loading ? (
        <Spin />
      ) : (
        <>
          <List>
            {items.map((o) => (
              <Item>
                <Title>{o.title}</Title>
                <Text>{o.text}</Text>
              </Item>
            ))}
          </List>
          {link ? (
            <Actions>
              <Link to={link.to}>{link.title}</Link>
            </Actions>
          ) : null}
        </>
      )}
    </Wrapper>
  </Col>
);
