import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import { devicesActions, devicesSelector, projectSelector } from '@store';
import { Table } from '@components';
import { DeviceModel, Queries } from '@types';

import { getColumns } from './config';
import { WrapActions, Wrapper } from './styles';
import { ModalAddDevice } from './components';

export const DeviceContent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const list = useSelector(devicesSelector.selectList);
  const projectItem = useSelector(projectSelector.selectItem);
  const [queries, setQueries] = useState<Queries>({});
  const [deleteIds, setDeleteIds] = useState<string[]>([]);
  const [undeployIds, setUndeployIds] = useState<string[]>([]);
  const [showModalAddDevice, setShowModalAddDevice] = useState(false);

  const fetchData = async () => {
    if (projectItem.result?.id && Object.keys(queries).length) await new Promise((res) => dispatch(devicesActions.fetchMany({ params: { project_id: projectItem.result?.id || '', ...queries }, onEnd: res })));
  };

  const onClickAddDevice = () => setShowModalAddDevice(true);

  const onClickUnregisted = async (device: DeviceModel) => {
    setDeleteIds((state) => [...state, device.id]);
    await new Promise((res) => dispatch(devicesActions.remove({ params: { id: device.id }, onEnd: res })));
    setDeleteIds((state) => state.filter((o) => o !== device.id));
    fetchData();
  };

  const onClickUndeploy = async (device: DeviceModel) => {
    setUndeployIds((state) => [...state, device.id]);
    await new Promise((res) => dispatch(devicesActions.undeploy({ params: { id: device.id }, onEnd: res })));
    setUndeployIds((state) => state.filter((o) => o !== device.id));
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [queries]);

  return (
    <Wrapper>
      <WrapActions>
        <Button type="primary" onClick={onClickAddDevice}>
          Add device
        </Button>
      </WrapActions>
      <Table data={list.result} loading={list.loading} columns={getColumns({ onClickUnregisted, deleteIds, onClickUndeploy, undeployIds, fetchData })} rowKey={(o) => o.id} onUpdateQueries={setQueries} />
      <ModalAddDevice visible={showModalAddDevice} setVisible={setShowModalAddDevice} fetchData={fetchData} />
    </Wrapper>
  );
};
