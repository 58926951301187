import styled from 'styled-components';

export const Content = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const WrapActions = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    & > * {
      width: 100%;
    }
  }
`;
