import { useState, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropDownProps, Menu, Dropdown as UIDropdown, Button, Checkbox, message } from 'antd';
import styled from 'styled-components';

import { devicesActions, devicesSelector, projectActions, projectSelector } from '@store';
import { DeviceModel, ModelModel } from '@types';
import { Empty } from '@components';

type Props = PropsWithChildren<Omit<DropDownProps, 'overlay'>> & { model: ModelModel; fetchDevices: () => Promise<void> };

type OnDropdownVisibleChange = (open: boolean) => void;

const Wrapper = styled(UIDropdown)``;

const WrapMenu = styled.div`
  background-color: white;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  text-align: center;
  padding-bottom: 14px;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const WrapList = styled(Menu)`
  max-height: 250px;
  overflow-y: scroll;
  box-shadow: unset;

  .ant-btn-text:hover,
  .ant-btn-text:focus {
    background-color: unset;
  }
`;

const Item = styled(Button)`
  width: 100%;
  text-align: left;
  display: flex;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

export const WrapEmpty = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  padding: 20px;

  svg {
    width: 80px;
  }
`;

export const ButtonDeploy = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 45px;
`;

export const ListDevices: React.FC<Props> = ({ model, fetchDevices, ...props }) => {
  const dispatch = useDispatch<AppDispatch>();
  const projectItem = useSelector(projectSelector.selectItem);
  const deviceList = useSelector(devicesSelector.selectList);
  const action = devicesActions.fetchMany;
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState<DeviceModel[]>([]);
  const [totalRecord, setTotalRecord] = useState(1);
  const [deployIds, setDeployIds] = useState<string[]>([]);
  const [deploying, setDeploying] = useState<boolean>(false);

  const fetchData = async (force?: boolean) => {
    try {
      if (!force && records.length >= totalRecord) return;

      setLoading(true);
      await new Promise((res) =>
        dispatch(
          action({
            params: {
              project_id: projectItem.result?.id || '',
              page: force ? 1 : Math.floor(records.length / 10) + 1,
              size: 10,
            },
            onSuccess: res,
          })
        )
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onScroll: React.UIEventHandler<HTMLElement> = (event) => {
    const element = event.target as HTMLElement;
    if (!loading && element.scrollTop + element.offsetHeight === element.scrollHeight) {
      element.scrollTo(0, element.scrollHeight);
      fetchData();
    }
  };

  const onDropdownVisibleChange: OnDropdownVisibleChange = (open) => {
    if (!open || loading) return;
    fetchData();
  };

  const onClickItem = (event: any, deviceModel: DeviceModel) => {
    event.stopPropagation();
    if (deviceModel.model_id) return;

    setDeployIds((state) => {
      if (state.some((o) => o === deviceModel.id)) return state.filter((o) => o !== deviceModel.id);
      return [...state, deviceModel.id];
    });
  };

  const onClickDeploy = async (event: any) => {
    event.stopPropagation();
    setDeploying(true);
    await Promise.all(deployIds.map((id) => new Promise((res) => dispatch(devicesActions.deploy({ params: { id, model_id: model.id }, onEnd: res })))));
    setDeploying(false);
    setDeployIds([]);
    setRecords([]);
    setTotalRecord(1);
    await fetchData(true);
    await fetchDevices();

    if (projectItem.result?.id) dispatch(projectActions.updateWorkflow({ params: { id: projectItem.result?.id, step: ['deployment'] } }));

    message.success(`Deploy model ${model.name} successfully.`);
  };

  useEffect(() => {
    setTotalRecord(deviceList.result.pagination.totalRecords);
    setRecords(deviceList.result.items);
    setDeployIds([]);
  }, [deviceList]);

  return (
    <Wrapper
      {...props}
      overlay={
        records.length ? (
          <WrapMenu>
            <WrapList
              items={records.map((o) => ({
                key: o.id,
                label: (
                  <Item type="text" onClick={(e) => onClickItem(e, o)}>
                    <Checkbox checked={Boolean(o.model_id) || deployIds.some((oo) => oo === o.id)} disabled={Boolean(o.model_id)} />
                    <p>{o.name}</p>
                  </Item>
                ),
              }))}
              onScroll={onScroll}
            />
            <Button onClick={onClickDeploy} loading={deploying}>
              Deploy
            </Button>
          </WrapMenu>
        ) : (
          <WrapEmpty>
            <Empty loading={loading} />
          </WrapEmpty>
        )
      }
      onVisibleChange={onDropdownVisibleChange}
      placement="bottomLeft"
    >
      <ButtonDeploy type="primary" loading={loading}>
        Deploy
      </ButtonDeploy>
    </Wrapper>
  );
};
