import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ApiSvg, DeviceSvg, MemberSvg, ProjectSvg } from '@assets/svgs';

const WrapMenu = styled.div`
  font-weight: 500;

  &,
  & .ant-menu,
  && .ant-menu-item {
    height: 100%;
  }
  & .ant-menu {
    display: flex;
    border-right: none;
  }
  && .ant-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    display: none;
  } */
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    && {
      margin-left: auto;
    }
    && .ant-menu-item {
      padding: 0 8px;
      &:last-child {
        margin-right: 8px;
      }
    }
  }
`;

const Text = styled.p`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    display: none;
  }
`;

const Icon = styled.div<{ size?: number }>`
  display: flex;

  svg {
    font-size: ${({ size }) => size || 20}px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

export const TopMenu: React.FC = () => {
  const location = useLocation();

  return (
    <WrapMenu>
      <Menu className="header-menu" selectedKeys={[location.pathname]}>
        <Menu.Item key={'/project/list'}>
          <Link to={'/project/list'}>
            <Text>Projects</Text>
            <Icon>
              <ProjectSvg />
            </Icon>
          </Link>
        </Menu.Item>
        <Menu.Item key={'/workspace/devices'}>
          <Link to={'/workspace/devices'}>
            <Text>Devices</Text>
            <Icon>
              <DeviceSvg />
            </Icon>
          </Link>
        </Menu.Item>
        <Menu.Item key={'/workspace/members'}>
          <Link to={'/workspace/members'}>
            <Text>Members</Text>
            <Icon>
              <MemberSvg />
            </Icon>
          </Link>
        </Menu.Item>
        <Menu.Item key={'/workspace/apis'}>
          <Link to={'/workspace/apis'}>
            <Text>API</Text>
            <Icon size={24}>
              <ApiSvg />
            </Icon>
          </Link>
        </Menu.Item>
      </Menu>
    </WrapMenu>
  );
};
