import { Button } from 'antd';
import styled from 'styled-components';

import { TextEllipsis } from '@components';

export const Wrapper = styled.div<{ loading?: string }>`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 400px;
  min-height: 260px;
  opacity: ${({ loading }) => (loading === 'true' ? 0.5 : 1)};
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 16px;
`;

export const TextHead = styled(TextEllipsis)`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.15px;
  color: black;
`;

export const TextTitle = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.25px;
  color: black;
  margin-bottom: 12px;
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    max-width: 50%;
  }

  & > *:last-child {
    color: ${({ theme }) => theme.colors.main.primary};
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const ButtonMenu = styled(Button)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 5px 0 5px 5px;
  margin-left: auto;

  &:hover,
  &:focus {
    background-color: unset;
  }
`;
