import { createSlice } from '@reduxjs/toolkit';

import { fetch, fetchMany, create, update, remove, removeMany } from './action';
import { State } from './type';

const initialState: State = {
  list: {
    loading: false,
    result: { items: [], pagination: { currentPage: 0, totalPages: 0, totalRecords: 0 } },
  },
  get: {
    loading: false,
  },
  create: {
    loading: false,
  },
  update: {
    loading: false,
  },
  delete: {
    loading: false,
  },
  deleteMultiple: {
    loading: false,
  },
};

export const slice = createSlice({
  name: 'pipelines',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.get.loading = true;
      state.get.error = {};
    });
    builder.addCase(fetch.fulfilled, (state) => {
      state.get.loading = false;
      state.get.error = {};
    });
    builder.addCase(fetch.rejected, (state, action) => {
      state.get.loading = false;
      state.get.error = action.error;
    });
    builder.addCase(fetchMany.pending, (state) => {
      state.list.loading = true;
      state.list.error = {};
    });
    builder.addCase(fetchMany.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload.data;
      state.list.error = {};
    });
    builder.addCase(fetchMany.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });
    builder.addCase(create.pending, (state) => {
      state.create.loading = true;
      state.create.error = {};
    });
    builder.addCase(create.fulfilled, (state) => {
      state.create.loading = false;
      state.create.error = {};
    });
    builder.addCase(create.rejected, (state, action) => {
      state.create.loading = false;
      state.create.error = action.error;
    });
    builder.addCase(update.pending, (state) => {
      state.update.loading = true;
      state.update.error = {};
    });
    builder.addCase(update.fulfilled, (state) => {
      state.update.loading = false;
      state.update.error = {};
    });
    builder.addCase(update.rejected, (state, action) => {
      state.update.loading = false;
      state.update.error = action.error;
    });
    builder.addCase(remove.pending, (state) => {
      state.delete.loading = true;
      state.delete.error = {};
    });
    builder.addCase(remove.fulfilled, (state) => {
      state.delete.loading = false;
      state.delete.error = {};
    });
    builder.addCase(remove.rejected, (state, action) => {
      state.delete.loading = false;
      state.delete.error = action.error;
    });
    builder.addCase(removeMany.pending, (state) => {
      state.delete.loading = true;
      state.delete.error = {};
    });
    builder.addCase(removeMany.fulfilled, (state) => {
      state.delete.loading = false;
      state.delete.error = {};
    });
    builder.addCase(removeMany.rejected, (state, action) => {
      state.delete.loading = false;
      state.delete.error = action.error;
    });
  },
});

// > Export
// * Action
export const actions = { ...slice.actions, fetch, fetchMany, create, update, remove, removeMany };
// * Reducer
export const { reducer } = slice;
