import { Tooltip, TooltipProps } from 'antd';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Text = styled.p<{ line: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ line }) => line};
  -webkit-box-orient: vertical;
`;

type Props = { tooltip?: TooltipProps; line?: number };

export const TextEllipsis: React.FC<PropsWithChildren<Props>> = ({ children, tooltip, ...props }) => (
  <Tooltip title={children} getTooltipContainer={(node) => node} {...tooltip}>
    <Text line={props.line || 1} {...props}>
      {children}
    </Text>
  </Tooltip>
);
