import { createSlice } from '@reduxjs/toolkit';

import { fetch, fetchMany, create, remove, removeMany, createSample, fetchManySample, labeling, annotate, update, fetchMediaAnomalyStat } from './action';
import { State } from './type';

const initialState: State = {
  list: {
    loading: false,
    result: { items: [], pagination: { currentPage: 0, totalPages: 0, totalRecords: 0 } },
  },
  listSample: {
    loading: false,
    result: { items: [], pagination: { currentPage: 0, totalPages: 0, totalRecords: 0 } },
  },
  getMediaAnomalyStat: {
    loading: false,
  },
  get: {
    loading: false,
  },
  create: {
    loading: false,
  },
  createSample: {
    loading: false,
  },
  labeling: {
    loading: false,
  },
  update: {
    loading: false,
  },
  delete: {
    loading: false,
  },
  deleteMultiple: {
    loading: false,
  },
};

export const slice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    refreshSample: (state) => {
      state.listSample = initialState.listSample;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.get.loading = true;
      state.get.error = {};
    });
    builder.addCase(fetch.fulfilled, (state) => {
      state.get.loading = false;
      state.get.error = {};
    });
    builder.addCase(fetch.rejected, (state, action) => {
      state.get.loading = false;
      state.get.error = action.error;
    });
    builder.addCase(fetchMany.pending, (state) => {
      state.list.loading = true;
      state.list.error = {};
    });
    builder.addCase(fetchMany.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload.data;
      state.list.error = {};
    });
    builder.addCase(fetchMany.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });
    builder.addCase(fetchManySample.pending, (state) => {
      state.listSample.loading = true;
      state.listSample.error = {};
    });
    builder.addCase(fetchManySample.fulfilled, (state, action) => {
      state.listSample.loading = false;
      state.listSample.result = action.payload.data;
      state.listSample.error = {};
    });
    builder.addCase(fetchManySample.rejected, (state, action) => {
      state.listSample.loading = false;
      state.listSample.error = action.error;
      state.listSample.result = initialState.listSample.result;
    });
    builder.addCase(fetchMediaAnomalyStat.pending, (state) => {
      state.getMediaAnomalyStat.loading = true;
      state.getMediaAnomalyStat.error = {};
    });
    builder.addCase(fetchMediaAnomalyStat.fulfilled, (state) => {
      state.getMediaAnomalyStat.loading = false;
      state.getMediaAnomalyStat.error = {};
    });
    builder.addCase(fetchMediaAnomalyStat.rejected, (state, action) => {
      state.getMediaAnomalyStat.loading = false;
      state.getMediaAnomalyStat.error = action.error;
    });
    builder.addCase(create.pending, (state) => {
      state.create.loading = true;
      state.create.error = {};
    });
    builder.addCase(create.fulfilled, (state) => {
      state.create.loading = false;
      state.create.error = {};
    });
    builder.addCase(create.rejected, (state, action) => {
      state.create.loading = false;
      state.create.error = action.error;
    });
    builder.addCase(createSample.pending, (state) => {
      state.createSample.loading = true;
      state.createSample.error = {};
    });
    builder.addCase(createSample.fulfilled, (state) => {
      state.createSample.loading = false;
      state.createSample.error = {};
    });
    builder.addCase(createSample.rejected, (state, action) => {
      state.createSample.loading = false;
      state.createSample.error = action.error;
    });
    builder.addCase(labeling.pending, (state) => {
      state.labeling.loading = true;
      state.labeling.error = {};
    });
    builder.addCase(labeling.fulfilled, (state) => {
      state.labeling.loading = false;
      state.labeling.error = {};
    });
    builder.addCase(labeling.rejected, (state, action) => {
      state.labeling.loading = false;
      state.labeling.error = action.error;
    });
    builder.addCase(update.pending, (state) => {
      state.update.loading = true;
      state.update.error = {};
    });
    builder.addCase(update.fulfilled, (state) => {
      state.update.loading = false;
      state.update.error = {};
    });
    builder.addCase(update.rejected, (state, action) => {
      state.update.loading = false;
      state.update.error = action.error;
    });
    builder.addCase(remove.pending, (state) => {
      state.delete.loading = true;
      state.delete.error = {};
    });
    builder.addCase(remove.fulfilled, (state) => {
      state.delete.loading = false;
      state.delete.error = {};
    });
    builder.addCase(remove.rejected, (state, action) => {
      state.delete.loading = false;
      state.delete.error = action.error;
    });
    builder.addCase(removeMany.pending, (state) => {
      state.delete.loading = true;
      state.delete.error = {};
    });
    builder.addCase(removeMany.fulfilled, (state) => {
      state.delete.loading = false;
      state.delete.error = {};
    });
    builder.addCase(removeMany.rejected, (state, action) => {
      state.delete.loading = false;
      state.delete.error = action.error;
    });
  },
});

// > Export
// * Action
export const actions = { ...slice.actions, fetch, fetchMany, fetchManySample, fetchMediaAnomalyStat, labeling, annotate, create, createSample, update, remove, removeMany };
// * Reducer
export const { reducer } = slice;
