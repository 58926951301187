import React, { PropsWithChildren, useEffect } from 'react';
import { Layout } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import { MainLayout } from '../MainLayout';

import { LayoutMaster } from './styles';

const { Content } = Layout;

export const ProjectLayout: React.FC<PropsWithChildren> = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) navigate('/auth');
  }, [isAuthenticated]);

  return (
    <MainLayout>
      <LayoutMaster>
        <Layout className="cra-main-layout">
          <Layout className="cra-main-layout-content">
            <Content className="cra-content">
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </LayoutMaster>
    </MainLayout>
  );
};
