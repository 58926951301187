import { createSlice } from '@reduxjs/toolkit';

import { fetchProject, fetchProjects, createProject, updateProject, updateWorkflow, deleteProject } from './action';
import { State } from './type';

const initialState: State = {
  list: {
    loading: false,
    result: { items: [], pagination: { currentPage: 0, totalPages: 0, totalRecords: 0 } },
  },
  item: {
    loading: false,
    result: undefined,
  },
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProject.pending, (state) => {
      state.item.loading = true;
      state.item.result = undefined;
      state.item.error = {};
    });
    builder.addCase(fetchProject.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload.data;
      state.item.error = {};
    });
    builder.addCase(fetchProject.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = undefined;
    });
    builder.addCase(fetchProjects.pending, (state) => {
      state.list.loading = true;
      state.list.result = initialState.list.result;
      state.list.error = {};
    });
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload.data;
      state.list.error = {};
    });
    builder.addCase(fetchProjects.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });
    builder.addCase(createProject.pending, (state) => {
      state.item.loading = true;
      state.item.result = undefined;
      state.item.error = {};
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });
    builder.addCase(createProject.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = undefined;
    });
    builder.addCase(updateProject.pending, (state) => {
      state.item.loading = true;
      state.item.result = undefined;
      state.item.error = {};
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload.data;
      state.item.error = {};
    });
    builder.addCase(updateProject.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = undefined;
    });
    builder.addCase(deleteProject.pending, (state) => {
      state.item.loading = true;
      state.item.result = undefined;
      state.item.error = {};
    });
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });
    builder.addCase(deleteProject.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = undefined;
    });
  },
});

// > Export
// * Action
export const actions = { ...slice.actions, fetchProject, fetchProjects, createProject, updateProject, updateWorkflow, deleteProject };
// * Reducer
export const { reducer } = slice;
