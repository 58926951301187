import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: RootState) => state.role;

export const selector = {
  selectData: createSelector(selectSelf, (state) => state),
  selectList: createSelector(selectSelf, (state) => state.list),
  selectDelete: createSelector(selectSelf, (state) => state.delete),
  selectUserRole: createSelector(selectSelf, (state) => state.userRole?.role),
};
