import { Tabs } from 'antd';
import styled from 'styled-components';

export const Content = styled.div``;

export const WrapperTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding-left: 25px;
    padding-right: 25px;
  }
`;
