import { Col, Spin } from 'antd';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DeviceModel } from '@types';
import { TextEllipsis } from '@components';

import { Card } from './styles';

const Wrapper = styled(Card)`
  & > *:not(:last-child) {
    margin-bottom: 6px;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Stats = styled.div`
  display: flex;
  flex-direction: row;

  & > *:first-child {
    color: ${({ theme }) => theme.colors.main.primary};
    border-bottom: 1px solid ${({ theme }) => theme.colors.main.primary};
  }
`;

const StatItem = styled.div`
  flex: 1;
  text-align: center;
  padding: 12px;
`;

const StatTitle = styled.p`
  margin-bottom: 4px;
`;

const StatInfo = styled.p`
  font-size: 1.71rem;
  line-height: 1;
  letter-spacing: 0.18;
`;

const Title = styled(TextEllipsis)`
  font-size: 1.71rem;
  line-height: 1;
  letter-spacing: 0.18px;
  color: black;
`;

const Tag = styled.div`
  background: #c8e6c9;
  border-radius: 4px;
  display: inline-block;
  padding: 2px 8px;

  font-size: 1.14rem;
  line-height: 1.71;
  letter-spacing: 0.15px;
`;

type Props = {
  device: DeviceModel;
  loading?: boolean;
};

type DataDay = {
  time: string;
  predicted: number;
  anomalies: number;
};

type DataChart = { data: number; axis: string };

export const CardChart: React.FC<Props> = ({ loading, device }) => {
  const [rate, setRate] = useState('0');
  const [detected, setDetected] = useState(0);
  const [average, setAverage] = useState('1');
  const [dataChart, setDataChart] = useState<DataChart[]>([]);

  useEffect(() => {
    if (!device?.id) return;
    const deployAnalyze = JSON.parse(localStorage.getItem('DEPLOYANALYZE') || '{}');
    const datas: DataDay[] = deployAnalyze[device.id] || [];
    const now = moment(new Date());
    const createdDay = moment(device.created_at * 1000)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0);
    const day = now.diff(createdDay, 'day');

    for (let i = 0; i < day - 1; i++) {
      const nday = createdDay.clone().add(i, 'day');
      let data = datas.find((o) => moment(o.time).day() === nday.day() && moment(o.time).month() === nday.month());
      if (data) continue;
      data = {
        time: nday.toString(),
        predicted: 28800,
        anomalies: Math.round(Math.random() * (Math.random() - 0.2 < 0 ? 2880 : 50)),
      };
      datas.push(data);
    }
    deployAnalyze[device.id] = datas;
    localStorage.setItem('DEPLOYANALYZE', JSON.stringify(deployAnalyze));

    const dataLastday = datas[datas.length - 1] || { predicted: 288000, anomalies: 0 };
    setRate(((100 * dataLastday.anomalies) / dataLastday.predicted).toFixed(2));
    setDetected(dataLastday.anomalies);
    setAverage(datas.reduce((r, o) => r * (1 - o.anomalies / o.predicted), 1).toFixed(2));

    const dataCaculate: DataChart[] = [];
    for (let i = 7; i > 0; i--) {
      const nday = createdDay.clone().add(i, 'day');
      const data = datas.find((o) => moment(o.time).day() === nday.day() && moment(o.time).month() === nday.month());
      if (!data) {
        dataCaculate.push({ data: 0, axis: nday.format('MMMM D') });
        continue;
      }
      dataCaculate.push({ data: (100 * data.anomalies) / data.predicted, axis: nday.format('MMMM D') });
    }
    setDataChart([...dataCaculate].reverse());
  }, [device]);

  return (
    <Col xs={24} md={12}>
      <Wrapper>
        {loading ? (
          <Spin />
        ) : (
          <>
            <Head>
              <Title line={1}>{device.name}</Title>
              <Tag>Online</Tag>
            </Head>
            <Stats>
              <StatItem>
                <StatTitle>Avg abnormal rate</StatTitle>
                <StatInfo>{rate}%</StatInfo>
              </StatItem>
              <StatItem>
                <StatTitle># Anomalies detected</StatTitle>
                <StatInfo>{detected}</StatInfo>
              </StatItem>
              <StatItem>
                <StatTitle>Avg confidence</StatTitle>
                <StatInfo>{average}</StatInfo>
              </StatItem>
            </Stats>
            <ReactECharts
              style={{
                width: '100%',
                height: 500,
              }}
              option={{
                xAxis: {
                  type: 'category',
                  data: dataChart.map((o) => o.axis),
                },
                yAxis: {
                  type: 'value',
                  max: 100,
                  axisLabel: {
                    formatter: '{value} %',
                  },
                },
                color: ['#C2185B'],
                series: [
                  {
                    data: dataChart.map((o) => o.data),
                    type: 'line',
                  },
                ],
              }}
            />
          </>
        )}
      </Wrapper>
    </Col>
  );
};
