import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-bottom: 16px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    & > * {
      margin-right: 16px;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 0.2}px) {
    justify-content: center;
  }
`;
