import { Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import { useEffect, useState } from 'react';

import { Breadcrumb, PageTitle } from '@components';
import { DeviceContent } from '@contents';

import { Wrapper, PageMainHeading } from '../../styles';

import { Content, WrapperTabs } from './styles';
import { ModelContent } from './contents';

const { TabPane } = Tabs;

export const DeploymentModelDevice = () => {
  const params = useParams<{ type: string }>();
  const [activeKey, setActiveKey] = useState('models');
  const navigate = useNavigate();

  const onChange = (key: string) => navigate(`/workspace/deployment/model-device/${key}`);

  useEffect(() => {
    const { type } = params;
    if (type === 'models' || type === 'devices') setActiveKey(type);
  }, [params]);

  return (
    <Wrapper>
      <PageTitle>Model & Device</PageTitle>
      <Breadcrumb>
        <BreadcrumbItem href="/workspace">Home</BreadcrumbItem>
        <BreadcrumbItem href="/workspace/deployment">Development</BreadcrumbItem>
        <BreadcrumbItem>Model & Device</BreadcrumbItem>
      </Breadcrumb>
      <PageMainHeading>Model & Device</PageMainHeading>
      <Content>
        <WrapperTabs activeKey={activeKey} onChange={onChange}>
          <TabPane tab="Models availaible to deploy" key="models">
            {activeKey === 'models' && <ModelContent />}
          </TabPane>
          <TabPane tab="Devices registered to Project" key="devices">
            {activeKey === 'devices' && <DeviceContent />}
          </TabPane>
        </WrapperTabs>
      </Content>
    </Wrapper>
  );
};
