import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Menu } from 'antd';
import { useEffect, useState } from 'react';

import { ThreeDotsSvg } from '@assets/svgs';
import { devicesActions, devicesSelector, modelsActions, projectSelector } from '@store';
import { DeviceModel, ModelModel } from '@types';
import { ModalConfirm, TextEllipsis } from '@components';

import { Wrapper, Content, Header, Body, TextHead, TextTitle, Line, ButtonMenu } from './styles';
import { ListDevices } from './ListDevices';

type Props = {
  data: ModelModel;
  fetchData: () => Promise<void>;
};

export const Item: React.FC<Props> = ({ data, fetchData }) => {
  const dispatch = useDispatch<AppDispatch>();
  const projectItem = useSelector(projectSelector.selectItem);
  const deviceList = useSelector(devicesSelector.selectList);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [devices, setDevices] = useState<DeviceModel[]>([]);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const onClickDelete = async () => {
    setLoadingDelete(true);
    await new Promise((res) => dispatch(modelsActions.remove({ params: { id: data.id }, onEnd: res })));
    setLoadingDelete(false);
    fetchData();
  };

  const fetchDevices = async () => {
    await new Promise((res) => dispatch(devicesActions.fetchMany({ params: { project_id: projectItem.result?.id || '' }, onSuccess: res })));
  };

  useEffect(() => {
    setDevices(deviceList.result.items.filter((o) => o.model_id === data.id));
  }, [deviceList]);

  useEffect(() => {
    if (!projectItem.result?.id) return;
    fetchDevices();
  }, [projectItem]);

  return (
    <Wrapper loading={String(loadingDelete)}>
      <Header>
        <TextHead line={1}>{data.name}</TextHead>
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  key: '1',
                  onClick: (e) => {
                    e.domEvent.stopPropagation();
                    setShowModalDelete(true);
                  },
                  label: (
                    <Button type="text" danger>
                      Delete
                    </Button>
                  ),
                },
              ]}
            />
          }
          placement="bottomLeft"
        >
          <ButtonMenu type="text">
            <ThreeDotsSvg />
          </ButtonMenu>
        </Dropdown>
      </Header>
      <Body>
        <Content>
          <TextTitle>{moment.unix(data.created_at).format('MMMM D')} model training</TextTitle>
          <Line>
            <p>Architect</p>
            <TextEllipsis line={1}>{data.architect_name}</TextEllipsis>
          </Line>
          <Line>
            <p>Trainning dataset</p>
            <TextEllipsis line={1}>{data.training_dataset_name}</TextEllipsis>
          </Line>
          <Line>
            <p>Device deployed to</p>
            <div>
              {!devices.length ? (
                <p>No devices</p>
              ) : (
                devices.map((o) => (
                  <TextEllipsis line={1} key={o.id}>
                    {o.name}
                  </TextEllipsis>
                ))
              )}
            </div>
          </Line>
        </Content>
        <ListDevices model={data} fetchDevices={fetchDevices} />
      </Body>
      <ModalConfirm title="Are you sure?" visible={showModalDelete} setVisible={setShowModalDelete} onClickOk={onClickDelete} confirmLoading={loadingDelete} okType="danger">
        Do you really to delete this records?
      </ModalConfirm>
    </Wrapper>
  );
};
