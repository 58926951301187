import { useEffect } from 'react';
import { Row, Col, Button as UIButton, Avatar } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { loginRequest } from '@constants';

export const Wrapper = styled.div`
  display: flex;
`;

export const Button = styled(UIButton)`
  display: flex;
  align-items: center;
  padding: 10px 81px;
`;

export const AzureLogin = () => {
  const { instance, accounts } = useMsal();

  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const getInitials = (string = '') => {
    string = string.replace(/\(.*\)/g, '').trim();
    const names = string.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const onLogin = () => {
    instance
      .loginRedirect(loginRequest)
      .then((loginResponse) => {
        // eslint-disable-next-line
        console.log('loginresonse', loginResponse);
      })
      .catch((e: any) => {
        // eslint-disable-next-line
        console.error(e);
      });
  };

  const onLogout = () => {
    localStorage.clear();
    const logoutRequest = {
      account: accounts[0],
      postLogoutRedirectUri: '/',
    };
    instance
      .logoutPopup(logoutRequest)
      .then(() => {
        // eslint-disable-next-line
        console.log('Logout');
      })
      .catch((e: any) => {
        // eslint-disable-next-line
        console.error(e);
      });
  };

  const renderLoggedIn = () => {
    const user = accounts[0];
    return (
      <div className="logged-box">
        <div className="user-dropdown">
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <Avatar size={40}>{getInitials(user?.name)}</Avatar>
            <div className="sub-user-dropdown">
              <Button type="link" onClick={() => onLogout()}>
                Logout <LogoutOutlined className="logout-icon" />
              </Button>
            </div>
          </a>
        </div>
      </div>
    );
  };

  const renderNotLoggedIn = () => (
    <div className="signin-box">
      <Button
        type="primary"
        block
        onClick={() => {
          onLogin();
        }}
      >
        Sign in with Fsoft account
      </Button>
    </div>
  );

  useEffect(() => {
    if (isAuthenticated) navigate('/project/list');
  }, [isAuthenticated]);

  return (
    <Wrapper>
      <Row gutter={30}>
        <Col span={24}>
          <div className="box-login">{isAuthenticated ? renderLoggedIn() : renderNotLoggedIn()}</div>
        </Col>
      </Row>
    </Wrapper>
  );
};
