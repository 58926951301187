import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import { useEffect, useState } from 'react';

import { Breadcrumb, Empty, PageTitle } from '@components';
import { architechturesActions, pipelinesActions, pipelinesSelector, projectSelector } from '@store';
import { Pagination, PipelineModel, Response } from '@types';

import { Wrapper, PageMainHeading } from '../../styles';

import { Content, List, WrapActions } from './styles';
import { ModelItem, ModalTrain } from './components';

export const ModelsPipelinePage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const listPipeline = useSelector(pipelinesSelector.selectList);
  const projectItem = useSelector(projectSelector.selectItem);
  const [showModalTrain, setShowModalTrain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PipelineModel[]>([]);

  const onClickTrain = () => setShowModalTrain(true);

  const fetchData = async () => {
    setLoading(true);
    const response: Response<Pagination<PipelineModel>> = await new Promise((res) => {
      if (projectItem.result?.id) dispatch(pipelinesActions.fetchMany({ params: { project_id: projectItem.result?.id || '', page: 1, size: 10 }, onSuccess: res }));
    });
    setData(response.data.items);

    setLoading(false);
  };

  const fetchMoreData = async () => {
    const pagination = listPipeline.result.pagination;
    const nextPage = pagination.currentPage + 1;
    if (nextPage > pagination.totalPages) return;

    setLoading(true);
    const response: Response<Pagination<PipelineModel>> = await new Promise((res) => {
      if (projectItem.result?.id) dispatch(pipelinesActions.fetchMany({ params: { project_id: projectItem.result?.id || '', page: nextPage, size: 10 }, onSuccess: res }));
    });

    setData((state) => [...state, ...response.data.items]);

    setLoading(false);
  };

  useEffect(() => {
    dispatch(architechturesActions.fetchMany({}));
    fetchData();
  }, []);

  useEffect(() => {
    const elem = document.querySelector('.ant-layout-content');
    if (!elem) return;
    const scrollHandle = (event: Event) => {
      const elememt: any = event.target;
      if (elememt && elememt.scrollTop + elememt.offsetHeight === elememt.scrollHeight) {
        fetchMoreData();
      }
    };
    elem.addEventListener('scroll', scrollHandle);
    return () => elem.removeEventListener('scroll', scrollHandle);
  }, []);

  return (
    <Wrapper>
      <PageTitle>Models pipeline</PageTitle>
      <Breadcrumb>
        <BreadcrumbItem href="/workspace">Home</BreadcrumbItem>
        <BreadcrumbItem href="/workspace/models">Models</BreadcrumbItem>
        <BreadcrumbItem>Pipeline</BreadcrumbItem>
      </Breadcrumb>
      <PageMainHeading>Models pipeline</PageMainHeading>
      <Content>
        <WrapActions>
          <Button type="primary" onClick={onClickTrain}>
            Train new model
          </Button>
        </WrapActions>
        {!data?.length ? (
          <Empty loading={loading} />
        ) : (
          <List>
            {data.map((o) => (
              <ModelItem key={o.id} data={o} fetchData={fetchData} />
            ))}
          </List>
        )}
      </Content>
      <ModalTrain visible={showModalTrain} setVisible={setShowModalTrain} onDone={fetchData} />
    </Wrapper>
  );
};
