import { Button } from 'antd';
import styled from 'styled-components';

import { Wrapper as WrapperLayout } from '../../styles';

export const Wrapper = styled(WrapperLayout)<{ fullscreen?: string }>`
  background-color: white;
  width: ${({ fullscreen }) => (fullscreen === 'true' ? '100vw' : 'unset')};
  height: ${({ fullscreen }) => (fullscreen === 'true' ? '100vh' : 'unset')};
  padding: ${({ fullscreen }) => (fullscreen === 'true' ? '20px 40px' : 'unset')};
`;

export const Content = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const WrapHead = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    width: 20px;
  }
`;

export const ButtonFullscreen = styled(Button)`
  padding: 0;
  display: flex;
  align-items: center;
`;
