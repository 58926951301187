import { Suspense, useEffect, useMemo, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import viVN from 'antd/lib/locale/vi_VN';
import enUS from 'antd/lib/locale/en_US';
import { useDispatch, useSelector } from 'react-redux';
import { EventCallbackFunction } from '@azure/msal-browser';

import { AppRouter } from '@router';
import { GlobalStyle, darkTheme, lightTheme } from '@assets/styles';
import { useAppNotiUpload, useLanguage } from '@helpers/hooks';
import { UILoading, ThemeSwitcher } from '@components';
import { locationActions, themeSelector, userActions } from '@store';
import { UserModel } from '@types';

export default function App() {
  // eslint-disable-next-line
  // console.log('env', process.env);
  useAppNotiUpload();
  const { instance, accounts } = useMsal();
  const theme = useSelector(themeSelector.selectTheme);
  const [loading, setLoading] = useState(true);
  const currentTheme = useMemo(() => (theme === 'dark' ? darkTheme : lightTheme), [theme]);
  const { language } = useLanguage();
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const callback: EventCallbackFunction = (params) => {
      if (params.error) instance.logout();
      if (params.error && loading) setLoading(false);
    };
    const eventId = instance.addEventCallback(callback);
    if (eventId) return () => instance.removeEventCallback(eventId);
  }, [instance]);

  useEffect(() => {
    (async () => {
      try {
        const AIC_LOGGED_USER = localStorage.getItem('AIC_LOGGED_USER');
        // // // eslint-disable-next-line
        // console.log('AIC_LOGGED_USER', AIC_LOGGED_USER);

        if (AIC_LOGGED_USER) {
          let userLogged: UserModel | undefined = undefined;
          try {
            userLogged = JSON.parse(AIC_LOGGED_USER);
          } catch (error) {}
          if (userLogged) dispatch(userActions.setUser(userLogged));
        }

        if (isAuthenticated && !AIC_LOGGED_USER) setLoading(true);

        if (isAuthenticated) {
          const inputData = await new Promise((res) =>
            dispatch(
              userActions.doLogin({
                params: { instance, accounts },
                onSuccess: (data) =>
                  res({
                    id: data.userId,
                    email: data.mail,
                    name: data.name,
                  }),
              })
            )
          );

          if (!AIC_LOGGED_USER) await new Promise((res) => dispatch(userActions.doRegister({ params: inputData, onError: () => {}, onEnd: res })));
          await new Promise((res) => dispatch(locationActions.getLocations({ params: {}, onEnd: res })));
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();
  }, [isAuthenticated]);

  if (loading) return <UILoading />;

  return (
    <Suspense
      fallback={
        <div className="loading-app">
          <UILoading />
        </div>
      }
    >
      <meta name="theme-color" content={currentTheme.colors.main.primary} />
      <ThemeProvider theme={currentTheme}>
        <GlobalStyle />
        <ConfigProvider locale={language === 'en' ? enUS : viVN}>
          <div className="App">
            <ThemeSwitcher theme={theme}>
              <AppRouter />
            </ThemeSwitcher>
          </div>
        </ConfigProvider>
      </ThemeProvider>
    </Suspense>
  );
}
