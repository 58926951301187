import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { State, UploadProgress } from './type';

const initialState: State = {};

export const slice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setProgress: (state, payload: PayloadAction<{ id: string; progress: UploadProgress }>) => {
      state[payload.payload.id] = payload.payload.progress;
    },
    uploadProgress: (state, payload: PayloadAction<{ id: string; progress: Partial<UploadProgress> }>) => {
      state[payload.payload.id] = { ...state[payload.payload.id], ...payload.payload.progress };
    },
    uploadProgressWithState: (state, payload: PayloadAction<{ id: string; handle: (state: UploadProgress) => UploadProgress }>) => {
      const nstate = payload.payload.handle(state[payload.payload.id]);
      state[payload.payload.id] = nstate;
    },
  },
});

// > Export
// * Action
export const actions = { ...slice.actions };
// * Reducer
export const { reducer } = slice;
