import { createSlice } from '@reduxjs/toolkit';

import { getLocation, getLocations, createLocation, updateLocation, deleteLocation } from './action';
import { State } from './type';

const initialState: State = {
  list: {
    loading: false,
    result: {},
  },
  item: {
    loading: false,
    result: {},
  },
};

export const slice = createSlice({
  name: 'location',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLocation.pending, (state) => {
      state.item.loading = true;
      state.item.result = {};
      state.item.error = {};
    });
    builder.addCase(getLocation.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });
    builder.addCase(getLocation.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = {};
    });
    builder.addCase(getLocations.pending, (state) => {
      state.list.loading = true;
      state.list.result = {};
      state.list.error = {};
    });
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = {
        data: {
          items: action.payload.data,
        },
      };
      state.list.error = {};
    });
    builder.addCase(getLocations.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = {};
    });
    builder.addCase(createLocation.pending, (state) => {
      state.item.loading = true;
      state.item.result = {};
      state.item.error = {};
    });
    builder.addCase(createLocation.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });
    builder.addCase(createLocation.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = {};
    });
    builder.addCase(updateLocation.pending, (state) => {
      state.item.loading = true;
      state.item.result = {};
      state.item.error = {};
    });
    builder.addCase(updateLocation.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });
    builder.addCase(updateLocation.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = {};
    });
    builder.addCase(deleteLocation.pending, (state) => {
      state.item.loading = true;
      state.item.result = {};
      state.item.error = {};
    });
    builder.addCase(deleteLocation.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });
    builder.addCase(deleteLocation.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = {};
    });
  },
});

// > Export
// * Action
export const actions = { ...slice.actions, getLocation, getLocations, createLocation, updateLocation, deleteLocation };
// * Reducer
export const { reducer } = slice;
