import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { DatasetModel, OptionData, Pagination, Response } from '@types';
import { datasetActions, projectSelector } from '@store';

type OnDropdownVisibleChange = (open: boolean) => void;
type OnScrollType = React.UIEventHandler<HTMLElement>;

export const useDatasetSelect = () => {
  const dispatch = useDispatch<AppDispatch>();
  const projectItem = useSelector(projectSelector.selectItem);
  const action = datasetActions.fetchMany;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<OptionData[]>([]);
  const [totalRecord, setTotalRecord] = useState(1);

  const fetchData = async () => {
    try {
      if (options.length >= totalRecord) return;

      setLoading(true);
      const response: Response<Pagination<DatasetModel>> = await new Promise((res) =>
        dispatch(
          action({
            params: {
              project_id: projectItem.result?.id,
              page: Math.floor(options.length / 10) + 1,
              size: 10,
            },
            onSuccess: res,
          })
        )
      );
      const noptions: OptionData[] = response.data.items.map((o) => ({ value: o.id, label: o.name }));
      setTotalRecord(response.data.pagination.totalRecords);
      setOptions((state) => [...state, ...noptions]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onDropdownVisibleChange: OnDropdownVisibleChange = (open) => {
    if (!open || loading) return;
    fetchData();
  };

  const onPopupScroll: OnScrollType = (event) => {
    const element = event.target as HTMLElement;
    if (!loading && element.scrollTop + element.offsetHeight === element.scrollHeight) {
      element.scrollTo(0, element.scrollHeight);
      fetchData();
    }
  };

  return { options, loading, onPopupScroll, onDropdownVisibleChange };
};
