import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, message, ModalProps, Select, Steps as UISteps } from 'antd';

import { ParamsCreate } from '@store/pipelines/type';
import { architechturesActions, architechturesSelector, pipelinesActions, pipelinesSelector, pipelineStepsActions, projectActions, projectSelector } from '@store';
import { Response } from '@types';
import { ModalConfirm } from '@components';

import { Wrapper, WrapperBody, WrapperJob, Steps, ItemInfoTitle, ItemInfoContent, ItemInfo, Content, Title } from './styles';
import { useDatasetSelect } from './hooks';

type Props = ModalProps & { setVisible: (value: boolean) => void; onDone: () => Promise<void> };

type FormType = Pick<ParamsCreate, 'name' | 'description' | 'architecture_id' | 'training_dataset_id' | 'evaluating_dataset_id'>;

const { Step } = UISteps;

export const ModalTrain: React.FC<Props> = ({ ...props }) => {
  const dispatch = useDispatch<AppDispatch>();
  const architectures = useSelector(architechturesSelector.selectList);
  const projectItem = useSelector(projectSelector.selectItem);
  const transactionCreate = useSelector(pipelinesSelector.selectCreateTransaction);
  const [form] = Form.useForm<FormType>();
  const datasetHook = useDatasetSelect();
  const [step, setStep] = useState(0);
  const [submitData, setSubmitData] = useState<ParamsCreate>();
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const onInvisible = async () => {
    props.setVisible(false);
  };

  const onFormSubmit = async (data: FormType) => {
    if (projectItem.result?.id) {
      setSubmitData({
        ...data,
        steps: ['preprocessing_data', 'training', 'evaluation', 'register'],
      });
      setStep(1);
    }
  };

  const createPipeline = async () => {
    const response: Response<{ id: string }> = await new Promise((res) => dispatch(pipelinesActions.create({ params: submitData, onSuccess: res })));
    dispatch(pipelineStepsActions.trigger({ params: { pipelineId: response.data.id } }));
    if (projectItem.result?.id) dispatch(projectActions.updateWorkflow({ params: { id: projectItem.result?.id, step: ['train-model'] } }));
    props.onDone();
    onInvisible();
    message.success('Create train model pipeline successfully.');
  };

  const onChangeStep = (value: number) => {
    setStep(value);
  };

  const onCancel = () => {
    if (submitData) setShowModalConfirm(true);
    else onInvisible();
  };

  const onOk = () => {
    if (step === 0) form.submit();
    else if (step === 1) createPipeline();
  };

  const onStepClick = (current: number) => {
    if (current > step) return;
    setStep(current);
  };

  useEffect(() => {
    if (props.visible) return;
    setStep(0);
    setSubmitData(undefined);
    form.resetFields();
  }, [props.visible]);

  useEffect(() => {
    dispatch(architechturesActions.fetchMany({}));
  }, []);

  return (
    <Wrapper {...props} title="Train new model" onCancel={onCancel} onOk={onOk} okButtonProps={{ htmlType: 'submit', form: 'train' }} okText={step === 1 ? 'Run' : 'Next'} confirmLoading={transactionCreate.loading} width={800} centered destroyOnClose>
      <WrapperBody>
        <Steps size="small" current={step} onChange={onChangeStep}>
          {['1.Model and dataset', '2.Start job'].map((o, i) => (
            <Step key={i} title={o} icon={<></>} onStepClick={onStepClick} disabled={step < i} />
          ))}
        </Steps>
        <Content>
          {step === 0 ? (
            <>
              <Title>Model infomations</Title>
              <Form form={form} name="train" onFinish={onFormSubmit} layout="vertical">
                <Form.Item name="name" className="material-input" label="Model name" rules={[{ required: true, message: 'Model name is required' }]}>
                  <Input id="name" placeholder="Model name" autoFocus maxLength={50} />
                </Form.Item>
                <Form.Item name="description" className="material-input" label="Description" rules={[{ required: true, message: 'Description is required' }]}>
                  <Input id="description" placeholder="Description" maxLength={255} />
                </Form.Item>
                <Form.Item name="training_dataset_id" className="material-input" label="Tranning dataset" rules={[{ required: true, message: 'Tranning dataset is required' }]}>
                  <Select {...datasetHook} placeholder="Dataset" showSearch allowClear showArrow>
                    {datasetHook.options.map((o) => (
                      <Select.Option key={o.value} value={o.value}>
                        {o.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="evaluating_dataset_id" className="material-input" label="Evaluate dataset" rules={[{ required: true, message: 'Evaluate dataset is required' }]}>
                  <Select {...datasetHook} placeholder="Dataset" showSearch allowClear showArrow>
                    {datasetHook.options.map((o) => (
                      <Select.Option key={o.value} value={o.value}>
                        {o.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="architecture_id" className="material-input" label="Model architecture" rules={[{ required: true, message: 'Model architecture is required' }]}>
                  <Select placeholder="Model architecture" loading={architectures.loading} showSearch allowClear showArrow>
                    {(architectures.result?.items || []).map((o) => (
                      <Select.Option key={o.id} value={o.id}>
                        {o.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="epoch" className="material-input" label="EPOCH" rules={[]} initialValue={10000}>
                  <Input id="epoch" placeholder="EPOCH" maxLength={50} disabled />
                </Form.Item>
                <Form.Item name="learning-rate" className="material-input" label="Learning rate" rules={[]} initialValue={0.01}>
                  <Input id="learning-rate" placeholder="Learning rate" maxLength={50} disabled />
                </Form.Item>
              </Form>
            </>
          ) : (
            <>
              <WrapperJob>
                <Title>Model infomations</Title>
                <ItemInfo>
                  <ItemInfoTitle>Name</ItemInfoTitle>
                  <ItemInfoContent>{submitData?.name}</ItemInfoContent>
                </ItemInfo>
                <ItemInfo>
                  <ItemInfoTitle>Description</ItemInfoTitle>
                  <ItemInfoContent>{submitData?.description}</ItemInfoContent>
                </ItemInfo>
                <ItemInfo>
                  <ItemInfoTitle>Trainning dataset</ItemInfoTitle>
                  <ItemInfoContent>{datasetHook.options.find((o) => submitData?.training_dataset_id === o.value)?.label}</ItemInfoContent>
                </ItemInfo>
                <ItemInfo>
                  <ItemInfoTitle>Evaluate dataset</ItemInfoTitle>
                  <ItemInfoContent>{datasetHook.options.find((o) => submitData?.evaluating_dataset_id === o.value)?.label}</ItemInfoContent>
                </ItemInfo>
                <ItemInfo>
                  <ItemInfoTitle>Architecture</ItemInfoTitle>
                  <ItemInfoContent>{architectures.result.items.find((o) => submitData?.architecture_id === o.id)?.name}</ItemInfoContent>
                </ItemInfo>
                <ItemInfo>
                  <ItemInfoTitle>EPOCH</ItemInfoTitle>
                  <ItemInfoContent>10000</ItemInfoContent>
                </ItemInfo>
                <ItemInfo>
                  <ItemInfoTitle>Learning rate</ItemInfoTitle>
                  <ItemInfoContent>0.01</ItemInfoContent>
                </ItemInfo>
              </WrapperJob>
            </>
          )}
        </Content>
      </WrapperBody>
      <ModalConfirm title="Are you sure?" visible={showModalConfirm} setVisible={setShowModalConfirm} onClickOk={onInvisible} confirmLoading={false} okType="danger">
        Are you sure to stop creating a train model pipeline?
      </ModalConfirm>
    </Wrapper>
  );
};
