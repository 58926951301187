import React, { PropsWithChildren } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// auth pages: no lazy loading for auth pages to avoid flickering
import LoginPage from '@pages/Auth/LoginPage';
// layouts
import { RequireAuth } from '@router';
import AdminLayout from '@layouts/AdminLayout';
import ProjectLayout from '@layouts/ProjectLayout';
import AppLayout from '@layouts/AppLayout';
import ModelsPipeline from '@pages/admin/Models/ModelsPipelinePage';
import ModelsDetail from '@pages/admin/Models/ModelsDetailPage';
import DeploymentOverview from '@pages/admin/Deployment/DeploymentOverview';
import DeploymentModelDevice from '@pages/admin/Deployment/DeploymentModelDevice';

import { withLoading } from './withLoading.hoc';

const AuthLayout = React.lazy(() => import('@layouts/AuthLayout'));

// application pages
const Home = React.lazy(() => import('@pages/Home/Home'));

// admin pages
const WorkflowPage = React.lazy(() => import('@pages/admin/Workflow/WorkflowPage'));
const MonitoringPage = React.lazy(() => import('@pages/admin/Monitoring'));
const APIs = React.lazy(() => import('@pages/admin/APIs'));
const Devices = React.lazy(() => import('@pages/admin/Devices'));
const Members = React.lazy(() => import('@pages/admin/Members'));

// project pages
const ProjectFormPage = React.lazy(() => import('@pages/admin/Projects/ProjectFormPage'));
const ProjectListPage = React.lazy(() => import('@pages/admin/Projects/components/ProjectListPage'));
const ProjectDetailPage = React.lazy(() => import('@pages/admin/Projects/ProjectDetailPage'));
const ProjectUserFormPage = React.lazy(() => import('@pages/admin/Projects/ProjectUserFormPage'));

// dataset pages
const DatasetsOverviewPage = React.lazy(() => import('@pages/admin/Datasets/DatasetsOverviewPage'));
const DatasetsDetailPage = React.lazy(() => import('@pages/admin/Datasets/DatasetsDetailPage'));
const DatasetsListPage = React.lazy(() => import('@pages/admin/Datasets/DatasetsListPage'));
// const DatasetsFeaturePage = React.lazy(() => import('@pages/admin/Datasets/DatasetsFeaturePage'));
const DatasetsSoundLibrariesPage = React.lazy(() => import('@pages/admin/Datasets/DatasetsSoundLibrariesPage'));
const DatasetsLabelingPage = React.lazy(() => import('@pages/admin/Datasets/DatasetsLabelingPage'));

const Error404Page = React.lazy(() => import('@pages/Errors/Error404Page'));
const Logout = React.lazy(() => import('./Logout'));

export const WORKSPACE_PATH = '/workspace';
export const PROJECT_PATH = '/project';
export const APP_PATH = '/app';

const Error404 = withLoading(Error404Page);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC<PropsWithChildren> = ({ children }) => {
  const protectedLayout = (
    <RequireAuth>
      <AdminLayout>{children}</AdminLayout>
    </RequireAuth>
  );

  const unProtectedLayout = <AppLayout>{children}</AppLayout>;

  const projectLayout = <ProjectLayout>{children}</ProjectLayout>;

  return (
    <BrowserRouter>
      <Routes>
        <Route path={WORKSPACE_PATH} element={protectedLayout}>
          <Route index element={<WorkflowPage />} />
          <Route path="workflow" element={<WorkflowPage />} />
          <Route path="monitoring" element={<MonitoringPage />} />
          <Route path="devices" element={<Devices />} />
          <Route path="apis" element={<APIs />} />
          <Route path="members" element={<Members />} />

          <Route path="data">
            <Route index element={<DatasetsOverviewPage />} />
            <Route path=":datasetId" element={<DatasetsDetailPage />} />
            <Route path="overview" element={<DatasetsOverviewPage />} />
            <Route path="dataset" element={<DatasetsListPage />} />
            <Route path="sound-libraries" element={<DatasetsSoundLibrariesPage />} />
            <Route path="label" element={<DatasetsLabelingPage />} />
            {/* <Route path="features" element={<DatasetsFeaturePage />} /> */}
          </Route>

          <Route path="models">
            <Route index element={<ModelsPipeline />} />
            <Route path="pipeline" element={<ModelsPipeline />} />
            <Route path=":modelId" element={<ModelsDetail />} />
          </Route>

          <Route path="deployment">
            <Route index element={<DeploymentOverview />} />
            <Route path="overview" element={<DeploymentOverview />} />
            <Route path="model-device" element={<DeploymentModelDevice />} />
            <Route path="model-device/:type" element={<DeploymentModelDevice />} />
          </Route>
        </Route>

        <Route path={PROJECT_PATH} element={projectLayout}>
          <Route index element={<ProjectListPage />} />
          <Route path="list" element={<ProjectListPage />} />
          <Route path="add" element={<ProjectFormPage />} />
          <Route path="detail/:projectId" element={<ProjectDetailPage />} />
          <Route path=":projectId/user/add" element={<ProjectUserFormPage />} />
          <Route path=":projectId/user/edit/:userId" element={<ProjectUserFormPage />} />
        </Route>

        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route index element={<LoginPage />} />
          <Route path="login" element={<LoginPage />} />
        </Route>

        <Route path={'/'} element={unProtectedLayout}>
          <Route index element={<Home />} />
        </Route>

        <Route path="404" element={<Error404 />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
