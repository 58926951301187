import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 0;
  min-height: calc(100vh - 130px);

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    min-height: calc(100vh - 155px);
  }
`;

export const PageMainHeading = styled.h1`
  font-size: 1.4em;
  color: #000;
`;
