import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Row } from 'antd';

import { devicesActions, devicesSelector, modelsActions, modelsSelector, projectSelector } from '@store';
import { PageTitle, Breadcrumb } from '@components';
import { FullscreenExitSvg, FullscreenSvg } from '@assets/svgs';
import { DeviceModel, Pagination, Response } from '@types';

import { PageMainHeading } from '../../styles';

import { CardChart, CardStat } from './components';
import { Wrapper, ButtonFullscreen, WrapHead, Content } from './styles';

export const DeploymentOverview: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const projectItem = useSelector(projectSelector.selectItem);
  const models = useSelector(modelsSelector.selectList);
  const devices = useSelector(devicesSelector.selectList);
  const handle = useFullScreenHandle();
  const [deviceTotal, setDeviceTotal] = useState<number>();
  const [deviceOnline, setDeviceOnline] = useState<number>();

  useEffect(() => {
    if (!projectItem.result?.id) return;
    dispatch(modelsActions.fetchMany({ params: { project_id: projectItem.result.id } }));
    dispatch(
      devicesActions.fetchMany({
        params: { project_id: projectItem.result.id },
        onSuccess: (res: Response<Pagination<DeviceModel>>) => {
          setDeviceTotal(res.data.pagination.totalRecords);
        },
      })
    );
    dispatch(
      devicesActions.fetchMany({
        params: { project_id: projectItem.result.id, filterby: 'status', filtervalue: 'deployed' },
        onSuccess: (res: Response<Pagination<DeviceModel>>) => {
          setDeviceOnline(res.data.pagination.totalRecords);
        },
      })
    );
  }, [projectItem]);

  const onClickFullscreen = () => {
    if (handle.active) handle.exit();
    else handle.enter();
  };

  return (
    <FullScreen handle={handle}>
      <Wrapper fullscreen={String(handle.active)}>
        <PageTitle>Deployment Overview</PageTitle>
        <Breadcrumb>
          <BreadcrumbItem href="/workspace">Home</BreadcrumbItem>
          <BreadcrumbItem href="/workspace/deployment">Development</BreadcrumbItem>
          <BreadcrumbItem>Overview</BreadcrumbItem>
        </Breadcrumb>
        <WrapHead>
          <PageMainHeading>Deployment Overview</PageMainHeading>
          <ButtonFullscreen type="text" onClick={onClickFullscreen}>
            {handle.active ? <FullscreenExitSvg /> : <FullscreenSvg />}
          </ButtonFullscreen>
        </WrapHead>
        <Content>
          <Row gutter={[16, 16]}>
            <CardStat
              loading={models.loading}
              items={[
                { title: 'Model available to deploy', text: models.result?.pagination?.totalRecords?.toString() || '0' },
                { title: 'Active models', text: models.result?.pagination?.totalRecords?.toString() || '0' },
              ]}
              link={{ title: 'View & Deploy', to: '/workspace/deployment/model-device' }}
            />
            <CardStat
              loading={devices.loading}
              items={[
                { title: 'Devices Registered', text: deviceTotal ? deviceTotal.toString() : '0' },
                { title: 'Online', text: deviceOnline ? deviceOnline.toString() : '0' },
                { title: 'Offline', text: deviceTotal && deviceOnline ? (deviceTotal - deviceOnline).toString() : '0' },
              ]}
              link={{ title: 'Manage', to: '/workspace/deployment/model-device/devices' }}
            />
          </Row>
          <Row gutter={[16, 16]}>
            {devices.result.items
              .filter((o) => Boolean(o.model_id))
              .map((o, i) => (
                <CardChart key={i} device={o} />
              ))}
          </Row>
        </Content>
      </Wrapper>
    </FullScreen>
  );
};
