import moment from 'moment';
import { Col, Row, Spin, Steps } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { PageTitle } from '@components';
import { pipelineStepsActions, projectSelector } from '@store';
import { snakeCaseToTitle } from '@helpers/utils';
import { Pagination, PipelineStepModel, Response } from '@types';

import { Wrapper, PageMainHeading } from '../../styles';

import { WrapperBody, Content, Back, Container, TextTitle, Line, TextCategory, TextDetail, WrapperStep } from './styles';

const { Step } = Steps;

const tabs = ['preprocessing_data', 'training', 'evaluation', 'register'];

export const ModelsDetailPage = () => {
  const params = useParams<{ modelId: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const projectItem = useSelector(projectSelector.selectItem);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<PipelineStepModel[]>([]);
  const dataStep = list.find((o) => o.step === tabs[current]);
  const refAPIInterval = useRef<any>();

  const clearAPIInterval = () => {
    if (refAPIInterval.current) {
      clearInterval(refAPIInterval.current);
      refAPIInterval.current = undefined;
    }
  };

  const fetchData = async () => {
    if (!params.modelId || !projectItem.result?.id) return;
    setLoading(true);
    const response: Response<Pagination<PipelineStepModel>> = await new Promise((res) => dispatch(pipelineStepsActions.fetchMany({ params: { project_id: projectItem.result?.id || '', pipeline_id: params.modelId || '' }, onSuccess: res })));
    setLoading(false);

    const arr = response.data.items;
    setList(arr);

    if (arr && (arr.some((o) => o.status === 'failed' || o.status === 'cancelled') || arr.every((o) => o.status === 'done'))) clearAPIInterval();
  };

  const createAPIInterval = () => {
    clearAPIInterval();
    refAPIInterval.current = setInterval(fetchData, 1000);
  };

  const onChange = (value: number) => {
    const data = list.find((o) => o.step === tabs[value]);
    if (!data || data.status === 'not started') return;
    setCurrent(value);
  };

  useEffect(() => {
    if (!params.modelId) return;
    fetchData();
    createAPIInterval();
    return () => clearAPIInterval();
  }, [params]);

  return (
    <Wrapper>
      <Back />
      <PageTitle>Job details</PageTitle>
      <PageMainHeading>Job details</PageMainHeading>
      <WrapperBody>
        {loading && !Boolean(list.length) ? (
          <Spin />
        ) : (
          <>
            <WrapperStep>
              <Steps type="default" size="small" current={current} onChange={onChange} className="site-navigation-steps">
                {tabs.map((o, i) => {
                  const data = list.find((oo) => oo.step === o);
                  const title = snakeCaseToTitle(o);
                  const timeEnd = data?.status === 'running' ? moment(new Date()) : data ? moment(data.updated_at * 1000) : undefined;
                  const timeStart = data ? moment(data.created_at * 1000) : undefined;
                  const time = !data || !timeEnd || data?.status === 'not started' ? '' : moment.utc(moment(timeEnd.diff(timeStart))).format('HH:mm:ss');
                  const status = data?.status === 'done' ? 'finish' : data?.status === 'running' ? 'process' : data?.status === 'cancelled' || data?.status === 'failed' ? 'error' : 'wait';
                  return <Step key={i} title={title} subTitle={time} status={status} />;
                })}
              </Steps>
            </WrapperStep>
            <Content>
              {dataStep ? (
                <Row gutter={[24, 24]}>
                  <Col xs={24} xl={12}>
                    <Container>
                      <TextTitle>Job info</TextTitle>
                      <Line>
                        <TextCategory>Job ID</TextCategory>
                        <TextDetail copyable>{dataStep.id}</TextDetail>
                      </Line>
                      <Line>
                        <TextCategory>Step</TextCategory>
                        <TextDetail>{snakeCaseToTitle(dataStep.step)}</TextDetail>
                      </Line>
                      <Line>
                        <TextCategory>Status</TextCategory>
                        <TextDetail status={dataStep.status}>{snakeCaseToTitle(dataStep.status)}</TextDetail>
                      </Line>
                      <Line>
                        <TextCategory>Command</TextCategory>
                        <TextDetail code>{dataStep.command}</TextDetail>
                      </Line>
                      <Line>
                        <TextCategory>Created at</TextCategory>
                        <TextDetail>{moment.unix(dataStep.created_at).format('MMMM Do YYYY, h:mm A')}</TextDetail>
                      </Line>
                    </Container>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Container>
                      <TextTitle>Data info</TextTitle>
                      <Line>
                        <TextCategory>Input</TextCategory>
                        <TextDetail>{dataStep.input}</TextDetail>
                      </Line>
                      <Line>
                        <TextCategory>Output</TextCategory>
                        <TextDetail>
                          <a href={dataStep.output}>{dataStep.output}</a>
                        </TextDetail>
                      </Line>
                    </Container>
                    {Boolean(dataStep.metrics) && Boolean(Object.keys(dataStep.metrics).length) && (
                      <Container>
                        <TextTitle>Metrics</TextTitle>
                        <Line>
                          <TextCategory>AUC</TextCategory>
                          <TextDetail>{dataStep.metrics.AUC}</TextDetail>
                        </Line>
                        <Line>
                          <TextCategory>Accuracy</TextCategory>
                          <TextDetail>{dataStep.metrics.Accuracy}</TextDetail>
                        </Line>
                        <Line>
                          <TextCategory>F1-score</TextCategory>
                          <TextDetail>{dataStep.metrics['F1-score']}</TextDetail>
                        </Line>
                      </Container>
                    )}
                  </Col>
                </Row>
              ) : (
                <Spin />
              )}
            </Content>
          </>
        )}
      </WrapperBody>
    </Wrapper>
  );
};
