import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Form, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import styled from 'styled-components';

import { Queries, RoleModel } from '@types';
import { projectSelector, roleSelector, projectActions, roleActions } from '@store';
import { ModalConfirm, Table } from '@components';

const WrapTable = styled.div`
  padding: 1rem 0;
`;

export const WrapperActions = styled.div`
  display: flex;

  & > *:not(:first-child) {
    margin-left: 12px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    & > *,
    & > * > button {
      width: 100%;
    }
  }
`;

type Props = { onClickAddUser?: () => Promise<void> };

export const UserList: React.FC<Props> = ({ onClickAddUser }) => {
  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const role = useSelector(roleSelector.selectUserRole);
  const projectItem = useSelector(projectSelector.selectItem);
  const projectId = params.projectId || projectItem.result?.id;
  const project = useSelector(projectSelector.selectItem);
  const list = useSelector(roleSelector.selectList);
  const deleteTransaction = useSelector(roleSelector.selectDelete);
  const [queries, setQueries] = useState<Queries & { project_id?: string }>({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const isCanUpdate = role === 'admin';

  const columns: ColumnsType<RoleModel> = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Date added',
      dataIndex: 'created_at',
      render: (created_at: string) => <span>{moment.unix(Number(created_at)).format('MMMM Do YYYY, h:mm A')}</span>,
      sorter: true,
    },
  ];

  const fetchData = () => {
    if (projectId && Object.keys(queries).length) dispatch(roleActions.fetchRoles({ params: { ...queries, project_id: projectId } }));
  };

  const handleDeleteUsersInProject = () =>
    new Promise((res) =>
      dispatch(
        roleActions.deleteRoles({
          params: { project_id: projectId, ids: selectedRowKeys },
          onSuccess: (response) => {
            if (response.success) {
              message.success('Delete users successfully.');
              fetchData();
            }
          },
          onEnd: res,
        })
      )
    );

  const onClickDelete = () => setShowModalDelete(true);

  const onClickAdd = async () => {
    if (onClickAddUser) await onClickAddUser();
    navigate(`/project/${projectId}/user/add`);
  };

  useEffect(() => {
    if (!projectItem) dispatch(projectActions.fetchProject({ params: { id: projectId } }));
    fetchData();
  }, [projectId, queries]);

  useEffect(() => {
    form.setFieldsValue(project.result);
  }, [project]);

  return (
    <>
      {isCanUpdate && (
        <WrapperActions>
          <Button onClick={onClickAdd}>Add user</Button>
          <Button danger onClick={onClickDelete} disabled={selectedRowKeys.length === 0}>
            Delete
          </Button>
        </WrapperActions>
      )}

      <WrapTable>
        <Table data={list.result} loading={list.loading} columns={columns} rowKey={(o) => o.user_id} defaultQueries={{ project_id: projectId }} selectedRowKeys={isCanUpdate ? selectedRowKeys : undefined} setSelectedRowKeys={isCanUpdate ? setSelectedRowKeys : undefined} onUpdateQueries={setQueries} />
      </WrapTable>
      <ModalConfirm title="Are you sure?" visible={showModalDelete} setVisible={setShowModalDelete} onClickOk={handleDeleteUsersInProject} confirmLoading={deleteTransaction.loading} okType="danger">
        Do you really to delete these records?
      </ModalConfirm>
    </>
  );
};
