import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserModel } from '@types';

import { doLogin, doRegister, fetchUsers, searchUsers } from './action';
import { State } from './type';

const initialState: State = {
  login: {
    loading: false,
    result: {},
  },
  logout: {
    loading: false,
    result: {},
  },
  register: {
    loading: false,
    result: {},
  },
  forgotpassword: {
    loading: false,
    result: {},
  },
  edit: {
    loading: false,
    result: {},
  },
  verify: {
    loading: false,
    result: {},
  },

  list: {
    loading: false,
    result: {},
  },
  item: {
    loading: false,
    result: {},
  },
  search: {
    loading: false,
  },
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, payload: PayloadAction<UserModel | undefined>) => {
      state.user = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.list.loading = true;
      state.list.result = {};
      state.list.error = {};
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = {};
    });
    builder.addCase(searchUsers.pending, (state) => {
      state.search.loading = true;
      state.search.error = {};
    });
    builder.addCase(searchUsers.fulfilled, (state) => {
      state.search.loading = false;
      state.search.error = {};
    });
    builder.addCase(searchUsers.rejected, (state, action) => {
      state.search.loading = false;
      state.search.error = action.error;
    });
    builder.addCase(doLogin.pending, (state) => {
      state.login.loading = true;
      state.login.result = {};
      state.login.error = {};
    });
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.login.loading = false;
      state.login.result = action.payload;
      state.login.error = {};
    });
    builder.addCase(doLogin.rejected, (state, action) => {
      state.login.loading = false;
      state.login.result = {};
      state.login.error = action.payload as any;
    });
    builder.addCase(doRegister.pending, (state) => {
      state.register.loading = true;
      state.register.result = {};
      state.register.error = {};
    });
    builder.addCase(doRegister.fulfilled, (state, action) => {
      state.register.loading = false;
      state.register.result = action.payload;
      state.register.error = {};
    });
    builder.addCase(doRegister.rejected, (state, action) => {
      state.register.loading = false;
      state.register.error = action.error;
      state.register.result = {};
    });
  },
});

// > Export
// * Action
export const actions = { ...slice.actions, doLogin, doRegister, fetchUsers, searchUsers };
// * Reducer
export const { reducer } = slice;
