export type WorkflowKeys = 'classification' | 'upload-media' | 'classification-definition' | 'labeling' | 'train-model' | 'deployment';

type WorkflowType = {
  message: string;
  key: WorkflowKeys;
};

export const WORKFLOW: WorkflowType[] = [
  { message: '1. Create your sound libraries', key: 'classification' },
  { message: '2. Upload, browse & manage your audio', key: 'upload-media' },
  { message: '3. Clarify and evaluate your sound libraries', key: 'classification-definition' },
  { message: '4. Label your data', key: 'labeling' },
  // { message: '5. Export dataset with training/dev/test/split', key: 'export-dataset' },
  { message: '5. Train and evaluate your model', key: 'train-model' },
  { message: '6. Deployment', key: 'deployment' },
];
