import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px !important;
  padding: 24px;

  & > *:not(:last-child) {
    margin-bottom: 32px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    border: none !important;
  }
`;

export const Title = styled.div`
  font-size: 1.71rem;
  line-height: 1;
  letter-spacing: 0.18px;
  text-align: center;
`;
