import { useState } from 'react';
import { Button, ButtonProps } from 'antd';

import { ModalConfirm, ModalConfirmProps } from './ModalConfirm';

export type ButtonConfirmProps = ButtonProps & { confirm: Omit<ModalConfirmProps, 'visible' | 'setVisible'> };

export const ButtonConfirm: React.FC<ButtonConfirmProps> = ({ confirm, ...props }) => {
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const onClick = () => setShowModalConfirm(true);

  return (
    <>
      <Button {...props} onClick={onClick} />
      <ModalConfirm {...confirm} visible={showModalConfirm} setVisible={setShowModalConfirm} />
    </>
  );
};
