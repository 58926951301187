import { BarSvg, DashboardSvg, DeploySvg, LightSvg, MonitoringSvg } from '@assets/svgs';

import { SidebarNavigationItem } from './type';

export const sidebarNavigationConfig: SidebarNavigationItem[] = [
  {
    title: 'ML workflow',
    key: '/workspace/workflow',
    url: '/workspace/workflow',
    icon: <DashboardSvg />,
  },
  {
    title: 'Data',
    key: '/workspace/data',
    icon: <BarSvg />,
    children: [
      {
        title: 'Overview',
        key: '/workspace/data/overview',
        url: '/workspace/data/overview',
      },
      {
        title: 'Dataset',
        key: '/workspace/data/dataset',
        url: '/workspace/data/dataset',
      },
      {
        title: 'Sound libraries',
        key: '/workspace/data/sound-libraries',
        url: '/workspace/data/sound-libraries',
      },
      {
        title: 'Label',
        key: '/workspace/data/label',
        url: '/workspace/data/label',
      },
    ],
  },
  {
    title: 'Models',
    key: '/workspace/models',
    icon: <LightSvg />,
    children: [
      {
        title: 'Pipeline',
        key: '/workspace/models/pipeline',
        url: '/workspace/models/pipeline',
      },
    ],
  },
  {
    title: 'Deployment',
    key: '/workspace/deployment',
    icon: <DeploySvg />,
    children: [
      {
        title: 'Overview',
        key: '/workspace/deployment/overview',
        url: '/workspace/deployment/overview',
      },
      {
        title: 'Model & Device',
        key: '/workspace/deployment/model-device',
        url: '/workspace/deployment/model-device',
      },
    ],
  },
  {
    title: 'Monitoring',
    key: '/workspace/monitoring',
    url: '/workspace/monitoring',
    icon: <MonitoringSvg />,
  },
];
