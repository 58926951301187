import { css } from 'styled-components';

export const themeStyle = css`
  .sider-drawer,
  .ant-layout-sider {
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: 0.1px;

    .ant-menu {
      border-right: none;
      padding: 12px 0;
    }

    && .ant-menu-title-content {
      margin-left: 25px;
    }

    .ant-menu-sub {
      background: none;
      padding: 0;

      & > .ant-menu-item > .ant-menu-title-content {
        margin-left: 21px;
      }
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      svg {
        width: 20px;
      }

      svg path {
        transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      &:hover,
      &:focus {
        svg path {
          fill: ${({ theme }) => theme.colors.main.primary};
        }
      }
    }

    .ant-menu-item,
    .ant-menu-submenu {
      svg path {
        fill: ${({ theme }) => theme.colors.text.main};
      }

      &-selected {
        svg path {
          fill: ${({ theme }) => theme.colors.main.primary};
        }
        &::after {
          display: none;
        }
      }
    }
  }

  .ant-modal {
    .ant-modal-header {
      border-bottom: none;
      padding-bottom: 8px;
    }

    .ant-modal-body {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .ant-modal-footer {
      border-top: none;
      padding-top: 8px;
    }
  }

  .ant-table {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;

    .ant-table-column-title {
      white-space: nowrap;
    }

    .ant-table-sticky-holder {
      background: none !important;
    }

    .ant-table-tbody .ant-table-row:last-child .ant-table-cell {
      border-bottom: none;
    }

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 0.2}px) {
      font-size: 0.9rem;

      .ant-table-cell {
        padding: 8px;
      }
    }
  }

  .ant-pagination {
    font-weight: 500;
  }

  .ant-pagination-mini {
    .ant-pagination-item-active {
      border: none;
    }
  }

  .ant-checkbox {
    &.ant-checkbox-checked .ant-checkbox-inner:after {
      left: 3px;
      top: 6px;
    }
  }

  .ant-drawer {
    .ant-drawer-header {
      padding-bottom: 8px;
      border-bottom: unset;
    }
    .ant-drawer-body {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .ant-drawer-footer {
      padding-top: 8px;
      border-top: unset;
    }
  }

  .ant-btn-default:not(:disabled):not(.ant-btn-dangerous) {
    color: ${({ theme }) => theme.colors.main.primary};
  }

  .ant-btn-default.ant-btn-dangerous:not(:disabled):not(:hover):not(:focus) {
    border-color: #d9d9d9;
  }

  .sider {
    &-layout {
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 0.2}px) {
        display: none;
      }
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        display: block;
      }
    }
    &-drawer {
      .ant-drawer-body {
        padding: 10px 0;
      }
      .ant-menu-sub {
        background: none !important;
      }
      .ant-menu {
        border-right: none;
      }
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 0.2}px) {
        display: block;
      }
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        display: none;
      }
    }
  }

  .head-dropdown {
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 0.2}px) {
      /* width: 100%; */
      left: 0px !important;
      right: 0px !important;
      width: 100%;

      & > * {
        width: 100%;
        border-radius: 0;
      }
    }
  }

  .ant-form-item {
    label.ant-form-item-required {
      &::before {
        content: none !important;
      }
      &::after {
        display: inline-block;
        margin-right: 4px;
        font-size: 0.875rem;
        line-height: 1em;
        color: rgba(0, 0, 0, 0.6);
        content: '*';
      }
    }
  }

  .material-input {
    &.ant-form-item {
      position: relative;

      .ant-form-item-label {
        position: absolute;
        z-index: 1;
        top: -7px;
        left: 15px;
        background: white;
        line-height: 1;
        padding: 0;

        label {
          color: rgba(0, 0, 0, 0.6);
          transition: all 0.3s;
        }
      }

      .ant-input {
        padding: 11px 11px;
      }

      .ant-select {
        .ant-select-selection-search {
          padding: 5px 0;
        }
        .ant-select-selector {
          padding: 7px 12px;
          height: unset;
        }
      }

      .ant-input-disabled,
      .ant-select-disabled .ant-select-selector {
        background: unset;
      }
    }

    &.ant-form-item-has-error {
      .ant-form-item-label label {
        color: ${({ theme }) => theme.colors.main.error};
      }
    }

    &:not(.ant-form-item-has-error):hover {
      .ant-form-item-label label {
        color: ${({ theme }) => theme.colors.main.primary};
      }
    }
  }
`;
