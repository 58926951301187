import styled from 'styled-components';

export const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const WrapActions = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 0.2}px) {
    & > * {
      width: 100%;
    }
  }
`;

export const WrapTableActions = styled.div`
  display: flex;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;
