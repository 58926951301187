import React, { PropsWithChildren } from 'react';
import { Layout } from 'antd';

import { Header } from '@components';

export const MainLayout: React.FC<PropsWithChildren> = ({ children }) => (
  <Layout className="cra-main-page">
    <Header />
    {children}
  </Layout>
);
