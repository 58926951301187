import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@api';
import { buildUrl } from '@helpers/utils';
import { ActionParams, UserModel } from '@types';
import { graphConfig, loginRequest } from '@constants';
import { createAsyncAction } from '@helpers/thunkWrapper';

import { actions } from './slice';

const callMsGraph = async (accessToken: string) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append('Authorization', bearer);
  const options = {
    method: 'GET',
    headers: headers,
  };
  return (
    fetch(graphConfig.graphMeEndpoint, options)
      .then((response) => response.json())
      // eslint-disable-next-line
      .catch((error) => console.log(error))
  );
};

export const fetchUsers = createAsyncThunk(
  'users/get_users',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl('/users', payload.params);
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);

export const searchUsers = createAsyncThunk(
  'users/search_users',
  createAsyncAction(async (payload: ActionParams<{ value: string; size?: number; skip?: number }, any>) => {
    const endpoint = buildUrl(`/search`, { type: 'user', ...payload.params });
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  })
);

export const doLogin = createAsyncThunk(
  'users/login',
  createAsyncAction(async (payload: ActionParams<any, any>, { dispatch }) => {
    const accessTokenRequest = {
      scopes: loginRequest.scopes,
      account: payload.params.accounts[0],
    };

    const accessTokenResponse: any = await payload.params.instance.acquireTokenSilent(accessTokenRequest);
    const response = await callMsGraph(accessTokenResponse.accessToken);
    const loginInfo: UserModel = {
      userId: response.id,
      phone: response.mobilePhone,
      mail: response.mail,
      name: response.displayName,
      token: accessTokenResponse.accessToken,
      officeLocation: response.officeLocation,
    };

    localStorage.setItem('AIC_LOGGED_USER', JSON.stringify(loginInfo));
    localStorage.setItem('AIC_TOKEN', accessTokenResponse.accessToken);
    api.setToken(accessTokenResponse.accessToken);
    if (payload.onSuccess) payload.onSuccess(loginInfo);

    dispatch(actions.setUser(loginInfo));

    return loginInfo;
  })
);

export const doRegister = createAsyncThunk(
  'users/register',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `/users`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  })
);
