import { Button } from 'antd';
import { useState } from 'react';

import { DeviceModel } from '@types';

import { ModalAddDevice } from './ModalAddDevice';

export const ButtonEdit: React.FC<{ fetchData: () => Promise<void>; data: DeviceModel }> = ({ fetchData, data }) => {
  const [showModalAddDevice, setShowModalAddDevice] = useState(false);

  return (
    <>
      <Button onClick={() => setShowModalAddDevice(true)}>Edit</Button>
      <ModalAddDevice visible={showModalAddDevice} setVisible={setShowModalAddDevice} fetchData={fetchData} data={data} />
    </>
  );
};
