import React from 'react';
import { useTranslation } from 'react-i18next';

import { LoginForm, PageTitle } from '@components';

const LoginPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.login')}</PageTitle>
      <LoginForm />
    </>
  );
};

export default LoginPage;
