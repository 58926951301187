import styled from 'styled-components';
import { Typography } from 'antd';

import { Back as UIBack } from '@components';
import { PipelineStepStatus } from '@types';

export const WrapperBody = styled.div`
  padding: 20px 0;
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const WrapperStep = styled.div`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  height: 300px;
  padding: 10px 0;
`;

export const Back = styled(UIBack)`
  margin-bottom: 15px;
`;

export const Container = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
  &:not(:last-child) {
    margin-bottom: 28px;
  }
`;

export const Line = styled.div`
  display: flex;
`;

export const TextTitle = styled.p`
  font-size: 1.14rem;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: #c2185b;
`;

export const TextCategory = styled.p`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.1px;
  color: black;
  width: 135px;
  margin-bottom: 0;
`;

export const TextDetail = styled(Typography.Paragraph)<{ status?: PipelineStepStatus }>`
  flex: 1;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.25px;
  margin-bottom: 0 !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  color: ${({ status, theme }) => (status === 'cancelled' ? theme.colors.main.warning : status === 'done' ? theme.colors.main.primary : status === 'failed' ? theme.colors.main.error : status === 'running' ? theme.colors.main.info : status === 'not started' ? theme.colors.text.main : theme.colors.text.main)};
`;
