import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModelModel, Pagination, Response } from '@types';
import { modelsActions, modelsSelector, projectSelector } from '@store';
import { Empty } from '@components';

import { Item } from './components';
import { List } from './styles';

export const ModelContent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const listPipeline = useSelector(modelsSelector.selectList);
  const projectItem = useSelector(projectSelector.selectItem);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ModelModel[]>([]);

  const fetchData = async () => {
    setLoading(true);
    const response: Response<Pagination<ModelModel>> = await new Promise((res) => {
      if (projectItem.result?.id) dispatch(modelsActions.fetchMany({ params: { project_id: projectItem.result.id, page: 1, size: 10 }, onSuccess: res }));
    });
    setData(response.data.items);

    setLoading(false);
  };

  const fetchMoreData = async () => {
    const pagination = listPipeline.result.pagination;
    const nextPage = pagination.currentPage + 1;
    if (nextPage > pagination.totalPages) return;

    setLoading(true);
    const response: Response<Pagination<ModelModel>> = await new Promise((res) => {
      if (projectItem.result?.id) dispatch(modelsActions.fetchMany({ params: { project_id: projectItem.result.id, page: nextPage, size: 10 }, onSuccess: res }));
    });

    setData((state) => [...state, ...response.data.items]);

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const elem = document.querySelector('.ant-layout-content');
    if (!elem) return;
    const scrollHandle = (event: Event) => {
      const element: any = event.target;
      if (element && element.scrollTop + element.offsetHeight === element.scrollHeight) {
        fetchMoreData();
      }
    };
    elem.addEventListener('scroll', scrollHandle);
    return () => elem.removeEventListener('scroll', scrollHandle);
  }, []);

  return (
    <>
      {data.length ? (
        <List>
          {data.map((o) => (
            <Item key={o.id} data={o} fetchData={fetchData} />
          ))}
        </List>
      ) : (
        <Empty loading={loading} />
      )}
    </>
  );
};
