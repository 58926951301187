import { createGlobalStyle } from 'styled-components';

import { customStyle } from './custom';
import { themeStyle } from './theme';

export const GlobalStyle = createGlobalStyle`
  ${themeStyle}
  ${customStyle}
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 1rem;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.commonColors.lightgrey};
    border-radius: 1.25rem;
    border: 0.375rem solid transparent;
    background-clip: content-box;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  input:-webkit-autofill{
    -webkit-text-fill-color: unset !important;
  }

  body {
    font-family: SF-Pro, sans-serif;
  }
  html {
    font-size: 14px;
    font-weight: 400;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: 12px;
    }
  }
  
  img {
    display: block;
  }
  p {
    margin-bottom: unset;
  }
`;
