import { Form } from 'antd';
import React from 'react';

import AzureLogin from '../../layouts/AzureLogin';

import { Wrapper, Title } from './styles';

export const LoginForm: React.FC = () => (
  <Form layout="vertical">
    <Wrapper>
      <Title>Sign in</Title>
      <AzureLogin />
    </Wrapper>
  </Form>
);
